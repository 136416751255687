export enum ERealtimeAction {
  ConnectionStatus = "connection_status",
  Error = "error",
  Event = "event",
  History = "history",
  Join = "join",
  Leave = "leave",
  Message = "message",
  PresenceFull = "presence_full",
  PresenceUpdate = "presence_update",
}
